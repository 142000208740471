module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["radioradio:n4,i4"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Radio Radio Live","short_name":"RaRa Live","start_url":"/","background_color":"#DCDCDC","theme_color":"#000000","display":"minimal-ui","icon":"src/media/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5203557907261ef272274ce110555245"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
